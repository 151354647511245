// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-404-js": () => import("/opt/build/repo/src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-index-js": () => import("/opt/build/repo/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-note-js": () => import("/opt/build/repo/src/templates/note.js" /* webpackChunkName: "component---src-templates-note-js" */),
  "component---src-templates-post-index-js": () => import("/opt/build/repo/src/templates/post-index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-tag-index-js": () => import("/opt/build/repo/src/templates/tag-index.js" /* webpackChunkName: "component---src-templates-tag-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

